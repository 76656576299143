import { SanityRecipe } from "@graphql-types";
import { Container, P, H1 } from "@util/standard";
import { BlocksContent, Image, Breadcrumb, Carousel } from "@global";
import styled from "styled-components";
import Sticky from "react-stickynode";

import * as React from "react";
import AllergenTags from "@shared/sub/allergensInner";
import RecipeMetrics from "./recipeMetrics";
import RecipeTags from "./recipeTags";
import RecipeSocialShare from "./socialShare";
import {
  CarouselLeftArrow,
  VideoIFrame,
  VideoWrapper,
} from "@shared/shared.styles";
import {
  colorsRGB,
  SMALL_LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  colors,
  vimeoUrls,
  assets,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { BreadcrumbPageContext } from "@util/types";
import { useStore } from "@state/store";
import { handleSaveRecipeProduct } from "@util/helper";
import { useCheckScreenWidth } from "@util/hooks";
interface Props {
  recipe?: SanityRecipe;
  pageContext: BreadcrumbPageContext;
}

const RecipeImageContainer = styled(Container)`
  .breadcrumb {
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 0;
    a {
      color: ${colorsRGB.merlin(0.7)};
      &:hover {
        color: ${colorsRGB.merlin(1)};
      }
    }
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    .breadcrumb {
      top: 100px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .breadcrumb {
      top: 73px;
    }
  }
`;

const HeartIcon = styled.div`
  position: absolute;
  top: 130px;
  right: 30px;
  z-index: 21;
  background-color: ${colors.merlin};
  width: 38px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    top: 110px;
  }
`;

const StyledSticky = styled(Sticky)`
  width: 50%;
  .breadcrumb {
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 0;
    a {
      color: ${colorsRGB.merlin(0.7)};
      &:hover {
        color: ${colorsRGB.merlin(1)};
      }
    }
  }
  .slick-slider {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    display: flex;
    height: 100%;
  }
  .slick-slide {
    height: inherit;
    > div {
      height: 100%;
    }
  }
  .slick-dots {
    bottom: 35px;
    text-align: left;
    padding: 0 25px;
    li {
      width: 9px;
      height: 9px;
      button {
        width: 9px;
        height: 9px;
        &:before {
          content: "";
          background: ${colors.white};
          border-radius: 100px;
          width: 9px;
          opacity: 0.7;
          height: 9px;
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    .breadcrumb {
      top: 100px;
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .breadcrumb {
      top: 73px;
    }
  }
`;

const NextIcon = (
  <CarouselLeftArrow position="absolute" right="5px" hideOnMobile>
    <img src={assets.arrowRight} alt="right-arrow" />
  </CarouselLeftArrow>
);

const PrevIcon = (
  <CarouselLeftArrow rightArrow position="absolute" left="5px" hideOnMobile>
    <img src={assets.arrowRight} alt="left-arrow" />
  </CarouselLeftArrow>
);

const RecipeHero = (props: Props) => {
  const { recipe, pageContext } = props;
  const { savedRecipes } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (recipe === null || recipe === undefined) {
    return null;
  }

  return (
    <Container
      width="100%"
      height="900px"
      display="flex"
      flexDirection="row"
      isMobileColumn
      overflow="hidden"
    >
      <StyledSticky enabled={false}>
        <Container
          height={isTabletWidth ? "80vh" : isMobileWidth ? "500px" : "900px"}
          width="100%"
          flexDirection="column"
        >
          <Breadcrumb pageContext={pageContext} colour="#fff" />
          <Carousel
            hideArrow={false}
            autoplay={false}
            iconButton
            prevIcon={PrevIcon}
            nextIcon={NextIcon}
            hideOnMobile
          >
            <Container
              display="flex !important"
              height="100%"
              justifyContent="center"
              alignItems="center"
              mobilePadding="70px 0 0"
            >
              <Container
                width="100%"
                height="100%"
                margin="auto"
                justifyContent="center"
                alignItems="center"
              >
                {recipe.info?.featuredMedia &&
                  !recipe.info?.featuredMedia.url && (
                    <Image
                      imageData={
                        recipe.info?.featuredMedia?.url
                          ? null
                          : recipe.info?.featuredMedia?.image?.asset
                              ?.gatsbyImageData
                      }
                      width="100%"
                      objectFit="cover"
                      height="100%"
                    />
                  )}

                {recipe.info?.featuredMedia?.url && (
                  <VideoWrapper width="50vw">
                    <VideoIFrame
                      src={vimeoUrls.background(
                        recipe.info?.featuredMedia?.url
                      )}
                      // @ts-ignore
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                      width="50vw"
                    />
                  </VideoWrapper>
                )}
              </Container>
            </Container>
            {recipe.info?.otherMedia &&
              recipe.info?.otherMedia.map(image => {
                if (!image) return;
                return (
                  <Image
                    width="100%"
                    height="100%"
                    key={image._key}
                    imageData={image.asset?.gatsbyImageData}
                    objectFit="cover"
                  />
                );
              })}
          </Carousel>
        </Container>
      </StyledSticky>
      {/* </RecipeImageContainer> */}
      <Container
        width="50%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        backgroundColor="white"
        mobileWidth="80%"
      >
        <HeartIcon>
          <img
            src={
              savedRecipes?.includes(recipe.id!)
                ? assets.favouritedIcon
                : assets.favouriteIcon
            }
            alt="icon-button"
            width="17px"
            height="17px"
            onClick={() => handleSaveRecipeProduct(recipe.id, "recipe")}
          />
        </HeartIcon>
        <Container width="370px" display="flex" flexDirection="column">
          {recipe.info && (
            <H1
              fontFamily="Doyle"
              fontSize={isTabletWidth || isMobileWidth ? 25 : 24}
              lineHeight="120%"
            >
              {recipe?.info.title}
            </H1>
          )}

          <AllergenTags allergens={recipe?.info?.allergens} />

          <RecipeMetrics recipe={recipe} />

          <BlocksContent blocks={recipe?.info?.recipeDescription?._rawBlocks} />

          {recipe.info && recipe?.info.authorName ? (
            <>
              {recipe?.info.authorLink ? (
                <P>
                  By{" "}
                  <a href={recipe.info.authorLink} target="_blank">
                    {recipe.info.authorName}
                  </a>
                </P>
              ) : (
                <P>By {recipe.info.authorName}</P>
              )}
            </>
          ) : (
            <P>By Barker's</P>
          )}

          {recipe?.info?.tags && <RecipeTags recipe={recipe} />}

          {recipe && <RecipeSocialShare recipe={recipe} />}
        </Container>
      </Container>
    </Container>
  );
};

export default RecipeHero;
