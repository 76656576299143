import { SanityRecipe } from "@graphql-types";
import { Container, P, VerticalSeparator } from "@util/standard";
import { Image } from "@global";

import * as React from "react";

interface Props {
  recipe?: SanityRecipe;
}

const RecipeMetrics = (props: Props) => {
  const { recipe } = props;
  const servingSize = recipe?.info?.servingSize;

  return (
    <Container width="100%" display="flex" flexDirection="row">
      {servingSize && (
        <>
          <P margin="0px">Serves {servingSize} {parseInt(servingSize) > 2 ? "people" : "person"}</P>
          <VerticalSeparator
            color="barkersDarkGray"
            width="1px"
            margin="0px 10px 0px 10px"
          />
        </>
      )}
      {recipe?.info?.prepTime && (
        <>
          <P margin="0px">{recipe?.info?.prepTime}mins Prep</P>
          <VerticalSeparator
            color="barkersDarkGray"
            width="1px"
            margin="0px 10px 0px 10px"
          />
        </>
      )}

      {recipe?.info?.cookTime && (
        <>
          <P margin="0px">{recipe?.info?.cookTime}mins Cook</P>
        </>
      )}
    </Container>
  );
};

export default RecipeMetrics;
